import { Component, createElement, createRef } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { debounce } from 'my-utils';

import SearchResult from 'my-components/SearchResult';

import Fade from '@mui/material/Fade';
import IconButton from '@mui/material/IconButton';
import InputAdornment from '@mui/material/InputAdornment';
import InputBase from '@mui/material/InputBase';
import LinearProgress from '@mui/material/LinearProgress';
import Paper from '@mui/material/Paper';
import Popper from '@mui/material/Popper';
import TextField from '@mui/material/TextField';

import { faCheck, faTimes } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import * as searchActions from 'my-actions/SearchActions';

class SearchField extends Component {
  state = {
    currentResults: this.props.currentResults || [],
    highlightedResult: 0,
    query: this.props.defaultValue || '',
    showResults: false,
  };
  wrapperEl = createRef();
  resultsEl = createRef();

  componentDidMount() {
    if (this.state.showResults) window.addEventListener('click', this.handleWindowClick);
  }
  componentWillUnmount() {
    if (this.state.showResults) window.removeEventListener('click', this.handleWindowClick);
  }

  componentDidUpdate(prevProps, prevState) {
    if (
      this.props.currentResults !== prevProps.currentResults ||
      this.props.previousResults !== prevProps.previousResults ||
      this.props.filterIds !== prevProps.filterIds ||
      this.props.searching !== prevProps.searching
    ) {
      const { createdItemPlacement, createNewItem, currentResults, filterIds, previousResults, searching } = this.props;
      const results = searching ? previousResults : currentResults;
      let filteredResults = (filterIds?.length ? results?.filter(r => !filterIds.includes(r.id)) : results) || [];
      if (!searching && this.state.query && createNewItem) {
        const newItem = createNewItem(this.state.query, filteredResults);
        if (newItem) {
          const insertAtStart = createdItemPlacement === 'start';
          const newItems = Array.isArray(newItem) ? newItem : [newItem];
          filteredResults = insertAtStart ? [...newItems, ...filteredResults] : [...filteredResults, ...newItems];
        }
      }
      this.setState({ currentResults: filteredResults });
    }
    if (prevState.showResults !== this.state.showResults) {
      if (this.state.showResults) window.addEventListener('click', this.handleWindowClick);
      else window.removeEventListener('click', this.handleWindowClick);
    }
  }

  handleWindowClick = e => {
    if (!(this.wrapperEl.current.contains(e.target) || this.resultsEl.current?.contains(e.target))) {
      this.setState({ showResults: false });
    }
  };

  handleChange = ({ target: { value: query } }) => {
    const { onChange } = this.props;
    this.setState({ query, showResults: true });
    this.updateSearchContext();
    onChange?.(query);
  };

  handleFocus = () => {
    this.setState({ showResults: true });
    if (this.props.searchOnEmpty) this.updateSearchContext();
  };

  handleKeyDown = e => {
    const { onKeyDown } = this.props;
    const { currentResults } = this.state;
    const highlightedResult = this.getHighlightedResult();
    const resultCount = currentResults?.length || 0;
    switch (e.key) {
      case 'Enter':
        this.handleEnter();
        e.preventDefault();
        break;
      case 'ArrowDown':
        if (resultCount) {
          this.setState({
            highlightedResult: (highlightedResult + 1) % resultCount,
          });
          e.preventDefault();
        }
        break;
      case 'ArrowUp':
        if (resultCount) {
          this.setState({
            highlightedResult: (highlightedResult + resultCount - 1) % resultCount,
          });
          e.preventDefault();
        }
        break;
      case 'Escape':
        this.setState({ showResults: false, highlightedResult: 0 });
        break;
    }
    onKeyDown && onKeyDown(e);
  };

  handleEnter = () => {
    const { createNewItem, disableSearch } = this.props;
    const { currentResults, query } = this.state;
    const resultCount = currentResults?.length || 0;
    const highlightedResult = this.getHighlightedResult();

    if (resultCount) {
      this.handleResultClick(currentResults[highlightedResult]);
    } else if (disableSearch && createNewItem && query) {
      this.handleResultClick(createNewItem(query, []));
    }
  };

  handleResultClick = result => {
    const { onResultClick, resultToQuery } = this.props;
    onResultClick && onResultClick(result);
    if (resultToQuery) this.setState({ query: resultToQuery(result) });
    this.hideResults();
  };

  handleClear = e => {
    const { onClear } = this.props;
    this.setState({ query: '' });
    onClear?.(e);
  };

  updateSearchContext = debounce(() => {
    const { contextKey, disableSearch, searchActions, searchOnEmpty, searchParams, type } = this.props;
    if (disableSearch) return null;
    const query = this.state.query.trim();
    searchActions.updateSearchContext(query, type, contextKey, searchParams, searchOnEmpty);
  }, 250);

  hideResults = () => this.setState({ showResults: false });

  getHighlightedResult() {
    const { currentResults, highlightedResult } = this.state;
    return Math.min(highlightedResult, currentResults.length - 1);
  }

  render() {
    const {
      basic,
      className,
      createNewItem,
      disabled,
      disableSearch,
      inputProps,
      onClear,
      PopperProps,
      resultWrapperStyle,
      searching,
      value,
      wrapperStyle,
    } = this.props;

    const { currentResults, query, showResults } = this.state;
    const resultsVisible = !!(showResults && (currentResults?.length || searching)) && !disableSearch;
    const defaultInputProps = {
      value: value ?? query,
      onChange: this.handleChange,
      onFocus: this.handleFocus,
      onKeyDown: this.handleKeyDown,
      inputProps: { autoComplete: 'off' },
    };
    const iProps = {
      ...defaultInputProps,
      disabled,
      ...inputProps,
      inputProps: {
        ...defaultInputProps.inputProps,
        ...inputProps.inputProps,
      },
    };
    if (disableSearch && createNewItem && query) {
      iProps.InputProps = {
        ...iProps.InputProps,
        endAdornment: (
          <InputAdornment position="end">
            <IconButton onClick={this.handleEnter} size="small">
              <FontAwesomeIcon icon={faCheck} />
            </IconButton>
          </InputAdornment>
        ),
      };
    } else if (onClear && !disabled) {
      iProps.InputProps = {
        ...iProps.InputProps,
        endAdornment: (
          <InputAdornment position="end" style={{ opacity: value || query ? 1 : 0 }}>
            <IconButton onClick={this.handleClear} size="small">
              <FontAwesomeIcon icon={faTimes} />
            </IconButton>
          </InputAdornment>
        ),
      };
    }
    const input = createElement(basic ? InputBase : TextField, iProps);

    return (
      <div className={className} ref={this.wrapperEl} style={{ position: 'relative', ...wrapperStyle }}>
        {input}
        <Popper
          anchorEl={this.wrapperEl.current}
          modifiers={popperModifiers}
          open={resultsVisible}
          placement="bottom-start"
          ref={this.resultsEl}
          style={{
            zIndex: 1300 + 1, // theme.zIndex.modal + 1 -- so works within Dialogs,
            ...resultWrapperStyle,
          }}
          transition
          {...PopperProps}
        >
          {({ TransitionProps }) => (
            <Fade {...TransitionProps} timeout={0}>
              <Paper
                css={{
                  maxHeight: '40vh',
                  overflow: 'auto',
                }}
                elevation={3}
                style={{ width: this.wrapperEl.current?.clientWidth }}
              >
                {searching && <LinearProgress />}
                {currentResults.map(this.renderResult)}
              </Paper>
            </Fade>
          )}
        </Popper>
      </div>
    );
  }

  setHighlightedIdx = idx => this.setState({ highlightedResult: idx });

  renderResult = (result, i) => {
    const { component = SearchResult, resultProps } = this.props;
    const highlightedResult = this.getHighlightedResult();
    const highlighted = i === highlightedResult;
    return createElement(component, {
      key: i, //result.id,
      result,
      resultIdx: i,
      highlighted,
      onResultClick: this.handleResultClick,
      onSetHighlightedIdx: this.setHighlightedIdx,
      ...resultProps,
    });
  };
}

const EMPTY_CONTEXT = { results: {} };
export default connect(
  (state, { contextKey, searchParams, type }) => {
    const ctxKey = contextKey || searchParams ? `${type}::${JSON.stringify(searchParams)}` : type;
    const searchContext = state.search[ctxKey] || EMPTY_CONTEXT;
    return {
      contextKey: ctxKey,
      searching: !!searchContext.searching,
      currentSearch: searchContext.current,
      previousResults: searchContext.results[searchContext.previous],
      currentResults: searchContext.results[searchContext.current],
    };
  },
  dispatch => ({
    searchActions: bindActionCreators(searchActions, dispatch),
  }),
)(SearchField);

const popperModifiers = [
  // { name: 'flip', enabled: false },
  // {
  //   name: 'offset',
  //   options: { offset: [0,20] }
  // },
  { name: 'preventOverflow', enabled: false, options: { altBoundary: true } },
  { name: 'hide', enabled: false },
];
