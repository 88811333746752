import { makeStyles } from '@mui/styles';
import classNames from 'classnames';

import { ONLINE_COURSE_STATUSES, ONLINE_COURSE_TYPES } from 'my-core/online-course-utils';

import Avatar from '@mui/material/Avatar';
import Tooltip from '@mui/material/Tooltip';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const useStyles = makeStyles(
  ({ palette, typography }) => ({
    root: {
      width: '2.5em',
      height: '2.5em',
      position: 'relative',
      overflow: 'visible',
      fontWeight: typography.fontWeightBlack,
    },
    ...Object.values(ONLINE_COURSE_TYPES).reduce(
      (mem, { color, value }) => (
        (mem[`type_${value}`] = {
          backgroundColor: palette[color].main,
          color: palette[color].contrastText,
        }),
        mem
      ),
      {},
    ),
    status_hidden: { opacity: 0.5 },
    status_preparing: {},
    status_published: {},
    status_closed: { backgroundColor: palette.grey[500] },
    read_access_public: { border: [[2, 'solid', palette.green.main]] },
    statusAvatar: {
      fontSize: '1em',
      width: '1em',
      height: '1em',
      position: 'absolute',
      right: '-0.2em',
      bottom: '-0.2em',
      borderRadius: '50%',
      backgroundColor: palette.grey[500],
    },
    statusIcon: { fontSize: '0.55em' },
  }),
  { name: 'OnlineCourseIcon' },
);

function OnlineCourseIcon(props) {
  const { className, onlineCourse, withStatus } = props;
  const { course_type, read_access, status } = onlineCourse;
  const classes = useStyles(props);

  const typeConfig = ONLINE_COURSE_TYPES[course_type];

  return (
    <Avatar
      className={classNames(
        classes.root,
        classes[`read_access_${read_access}`],
        classes[`type_${course_type}`],
        classes[`status_${status}`],
        className,
      )}
      size={32}
    >
      {typeConfig ? typeConfig.abbrv : '?'}
      {withStatus && (
        <Tooltip placement="right" title={ONLINE_COURSE_STATUSES[status].label}>
          <Avatar className={classes.statusAvatar}>
            <FontAwesomeIcon className={classes.statusIcon} icon={ONLINE_COURSE_STATUSES[status].icon} />
          </Avatar>
        </Tooltip>
      )}
    </Avatar>
  );
}

export default OnlineCourseIcon;
