import standardApiRequestAction from 'my-core/standardApiRequestAction';

import types from '../constants/SearchActionTypes';
import API from '../core/api';

export function updateSearchContext(query, type, contextKey, params = {}, searchOnEmpty) {
  return (dispatch, getState) => {
    const ctx = getState().search[contextKey];
    const hasResults = !!ctx?.results[query];
    const shouldPerformSearch = !hasResults && (query || searchOnEmpty);
    dispatch({ type: types.SET_CURRENT_SEARCH_QUERY, query, contextKey, searching: shouldPerformSearch });
    if (shouldPerformSearch)
      API.getJSON(`/search/${type}`, { ...params, query })
        .then(results => {
          if (results) {
            dispatch({
              type: types.FETCH_SEARCH_RESULTS_SUCCESS,
              query,
              contextKey,
              results,
            });
          }
        })
        .catch(error => {
          console.error(error);
          dispatch({
            type: types.FETCH_SEARCH_RESULTS_FAIL,
            query,
            contextKey,
            error,
          });
        });
  };
}

export function searchLibrary(fetchParams, requestKey, page) {
  return standardApiRequestAction({
    path: '/api/v1/search/library',
    body: fetchParams,
    parentRequestKey: requestKey,
    requestKey: page,
  });
}
